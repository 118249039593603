<template>
  <div class="live5g p_r">
    <van-nav-bar title="5G直播" :border='false'>
      <template #left>
        <van-icon :size="24" @click="revert()" color="#141212" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <div class="cont5G">
      <div class="head_bg p_r">
        <img src="./img/5g_head.png" alt="">
        <div class="d_f page_out">
          <div class="flex_1" @click="pre"></div>
          <div class="flex_1" @click="next"></div>
        </div>
      </div>
      <div class="d_f ali_c j_s foot_name">
        <div class="f_line"></div>
        <div class="f_name t_a">{{video.name}}</div>
        <div class="f_line"></div>
      </div>
      <!--<div class="content">
        <div class="p_r c_out d_f ali_c j_c">
          <template>
            <img :src="cover" alt="" class="p_a c_bg">
            <div class="p_a c_cover"></div>
            <div class="c_play p_r">
              <img src="./img/5g_play.png" alt="" @click="playByApp">
            </div>
          </template>
        </div>-->
      <div class="content">

        <video id="video" class="video-js vjs-default-skin vjs-big-play-centered" controls preload="none">
        </video>
        <!--<template>
          <iframe v-if="!isM3u8 && isPlay" :src="video.interlinkage" width="100%" style="overflow: hidden; border-radius: 5px;" height="188px" class="iframe_5g"  scrolling="no" allowfullscreen allow="autoplay"></iframe>
        </template>-->
        <!--<div class="p_r c_out d_f ali_c j_c" v-if="!isPlay">
          <template>
            <img :src="cover" alt="" class="p_a c_bg">
            <div class="p_a c_cover"></div>
            <div class="c_play p_r">
              <img src="./img/5g_play.png" alt="" @click="playByApp">
            </div>
          </template>
        </div>-->
      </div>
      <div class="hot_title"></div>
      <div class="item_out d_f j_s">
        <div class="items p_r" v-for="(item,index) in hot" @click="reloadLive(item.videoId)" :key="index">
          <div class="img_out p_r">
            <van-image :src="item.cover" fit="cover" style="width: 100%;height: 100%;">
              <template v-slot:error>
                <img src="./img/noImg.png" style="width: 100%;height: 100%;" alt="">
              </template>
            </van-image>
            <div class="p_a top t_a">TOP{{index + 1}}</div>
            <div class="p_a bottom t_a">{{item.playNumber}}人浏览过</div>
          </div>
          <div class="name">{{item.name}}</div>
        </div>
      </div>
    </div>
    <div class="foot_bg p_a">
      <img src="./img/5g_foot.png" alt="">
    </div>
  </div>
</template>
<!--video.interlinkage-->
<script>
  // let Hls = require('hls.js');
  import Hls from 'hls.js'
  import { Toast, Image, NavBar, Icon } from 'vant'
  import Video from 'video.js'
  import 'video.js/dist/video-js.css'

  export default {
    name: "live5g",
    components:{
      'van-image' : Image,
      'van-icon' : Icon,
      'van-nav-bar' : NavBar,
    },
    data() {
      return {
        apis:{//接口地址
          video:'/video/findVideo5G',//获取视频详情
          slicedQueryVideo:'/video/slicedQueryVideo',//获取视频详情
        },
        cover:'',
        params:{
          videoId:'',
          videoType:1,
        },
        video:{},
        isPlay:false,
        nextVideo:{},
        prevVideo:{},
        hot:[],
        chls:null,
        isM3u8:false,
        myPlayer:null,

      }

    },
    mounted(){
    },
    created(){
      this.myPlayer ? this.myPlayer.dispose() : '';
      this.params.videoId = sessionStorage.getItem('videoId') ?  sessionStorage.getItem('videoId') : this.$route.params.id;
      this.loadVideo();
      this.getHot()
    },
    beforeRouteLeave(){
      this.myPlayer.dispose();
    },
    methods:{
      reloadLive(videoId){
        sessionStorage.setItem('videoId',videoId);
        this.myPlayer.dispose();
        window.location.reload();
        /*this.params.videoId = sessionStorage.getItem('videoId') ?  sessionStorage.getItem('videoId') : this.$route.params.id;
        this.loadVideo();
        this.getHot()*/
      },
      getHot(){
        let _t = this;
        _t.$get({
          url:_t.apis.slicedQueryVideo,
          params:{
            pageIndex:1,
            pageSize:3,
            videoType:1,
            sortName:'play_number',
            sortOrder:'desc',
          }
        }).then(rsp => {
          this.hot = rsp.data
        });
      },
      pre(){
        let _t = this;
        if(!_t.prevVideo) return _t.$toast('已经是第一条啦~');
        _t.reloadLive(_t.prevVideo.videoId)
      },
      next(){
        let _t = this;
        if(!_t.nextVideo) return _t.$toast('已经是最后一条啦~');
        _t.reloadLive(_t.nextVideo.videoId)

      },
      revert(){
        this.$router.go(-1)
      },

      loadVideo(){//获取视频对象
        const me = this;

        me.$get({
          url:me.apis.video,
          params:me.params
        }).then(rsp => {
          me.video = rsp ? rsp.currentVideo : {};
          me.nextVideo = rsp ? rsp.nextVideo : {};
          me.prevVideo = rsp ? rsp.prevVideo : {};
          me.cover = me.video.cover;

          me.myPlayer = Video('video', {
            //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
            controls: true,
            //自动播放属性,muted:静音播放
            muted: true,
            autoplay: true,
            //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
            preload: "auto",
            //设置视频播放器的显示宽度（以像素为单位）
            // width: "960px",
            //设置视频播放器的显示高度（以像素为单位）
            // height: "522px",
            // url
            // poster: 'https://static.shuxuejia.com/img/video_image.png', // 封面图片
            sources: [{
              src: me.video.interlinkage
            }],
            playbackRates: [0.5, 1, 1.5, 2] //倍速播放

          }, function onPlayerReady() {
            Video.log('Your player is ready!'); // 比如： 播放量+1请求

            this.on('ended', function() {
              Video.log('Awww...over so soon?!');
            });
          });
        });
      },
    }
  }
</script>

<style scoped lang="scss">
  .item_out{
    padding: 0 21px;
    .name{
      font-size: 13px;
      font-weight: 500;
      color: #3E3E3E;
      margin-top: 8px;
      position: relative;
      z-index: 999;
      width: 104px;
    }
    .img_out{
      z-index: 999;
      .bottom{
        padding: 2px 3px;
        height: 14px;
        border: 1px solid #FFFFFF;
        border-radius: 2px;
        bottom: 3px;
        right: 3px;
        font-size: 8px;
        font-weight: 500;
        color: #FFFFFF;
      }
      .top{
        font-size: 12px;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;width: 49px;
        height: 21px;
        background: linear-gradient(75deg,#FF6113, #F90F0F, #C60909 );
        border-radius: 3px 0px 3px 0px;
        line-height: 21px;
        left: 0;
        top: 0;
      }
      width: 104px;
      height: 104px;
    }
  }
  .hot_title{
    width: 154px;
    height: 16px;
    margin: 14px auto;
    background: url("./img/5g_hot_title.png") no-repeat;
    background-size: 100% 100%;
  }
  .page_out{
    background: url("./img/5g_page.png") no-repeat;
    background-size: 100% 100%;
    width: 180px;
    height: 40px;
    position: absolute;
    bottom: 0;
    left: calc(50% - 90px);
    >div{
      height: 100%;
    }
  }
  .foot_bg img{
    width: 100%;
  }
  .foot_bg{
    width: 100%;
    height: 64px;
    bottom: 0;
    left: 0;
  }
  .f_name{
    font-size:13px;
    font-weight:500;
    color:rgba(255,255,255,1);
  }
  .f_line{
    width: 20%;
    height: 1px;
    background-color: white;
  }
  .foot_name{
    position: relative;
    z-index: 1;
    padding: 14px 21px;
  }
  .c_play{
    width: 60px;
    height: 60px;
  }
  .c_play img{
    width: 100%;
    cursor: pointer;
  }
  .c_bg{
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  .c_cover{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.51);
    left: 0;
    top: 0;
  }
  .c_out,iframe{
    overflow: hidden;
    height:28vh;
    border: 2px solid rgba(255,255,255,1);
    border-radius:3px;
  }
  iframe{
    outline: none;
    height: 188px;
    *{
      background-color: #81CDC1!important;
    }
  }
  .content{
    padding: 0 21px;
    position: relative;
    z-index: 1;
    height: 28vh;
    box-sizing: border-box;
  }
  .content>div{
    width: 100%;
    height: 100%;
  }
  .content video{
    width: 100%;
    height: 100%;
  }

  .head_bg img{
    width: 100%;
  }
  .cont5G{
    width: 100%;
    height: 100%;
    background-color: #81CDC1;
  }
  .live5g{
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
</style>
